import * as React from "react";
import Navbar from "../sections/Navbar/Navbar";
import MediaPartnersLogo from "../sections/MediaPartnersLogos/MediaPartnersLogo";
import Hero from "../sections/Hero/Hero";
import RegBanner from "../sections/RegBanner/RegBanner";
import Stats from "../sections/Stats/Stats";
import DemoDay from "../sections/DemoDay/DemoDay";
import Mentors from "../sections/Mentors/Mentors";
import Timeline from "../sections/Timeline/Timeline.js";
import Video from "../sections/Video/Video";
import WhoWeWorkWith from "../sections/WhoWeWorkWith/WhoWeWorkWith";
import Media from "../sections/Media/Media";
import Footer from "../sections/Footer/Footer";
import "./global.css";
import { Helmet } from "react-helmet";
import "@progress/kendo-theme-default/dist/all.css";
import { ChakraProvider } from "@chakra-ui/react";
import InvestorsPartners from "../sections/InvestorPartners/InvestorPartners";
import Timer from "../components/Timer/Timer";

import "../fonts/stylesheet.css";

// styles
const text = {
  fontFamily: "Manrope",
};

// markup
const IndexPage = () => {
  return (
    <main style={text}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="Conquest '24"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="https://i.imgur.com/HBGpRL8.png"/>
        <title>Conquest: India's First and Largest Student-run Startup Accelerator</title>
      </Helmet>
      <Navbar />
      {/* <Timer /> */}
      <Hero />
      <Stats />
      <Video />
      <WhoWeWorkWith />
      {/* <DemoDay /> */}
      <Timeline />
      {/* <Mentors /> */}
      <InvestorsPartners />
      <Media />
      <RegBanner />
      <Footer />
      <script src="https://cdn.popupsmart.com/bundle.js" data-id="25924" async defer></script>
    </main>
  );
};

export default IndexPage;
